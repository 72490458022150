@import './functions';
// ==============================================================================
// path variables
// ==============================================================================
$backgroundBigGlitch: '/_next/image?url=%2Fbackground-big-glitch.webp&w=1920&q=75'; // file size cut from 76.9 kB B to 19.1 kB
$mikemoRaffleBackground: '/mo-giveaway.jpg';
$logoOfficialABD: '/ABD-logo-offical.png';
$commonThumbnail: '/common-thumbnail.jpg';
$rareThumbnail: '/rare-thumbnail.jpg';
$vaultedRareThumbnail: '/vaultedRare-thumbnail.jpg';
$legendaryThumbnail: '/legendary-thumbnail.jpg';
$mikemoOfficial: '/mikemoOfficial.png';
$premiumPack: '/premiumPack.png';
$mikemoExplosionDesktop: '/_next/image?url=%2Fmikemo-explosion-desktop.jpg&w=1920&q=75'; // using our custom image handler to resize the image making it smaller to load. No discernable difference in quality btw 75 and 100, but saves cuts file size in half.
$seriesTwoBackground: '/prod-desktop-banner.jpg';
$seriesTwoBackgroundMobile: '/prod-mobile-banner.jpg';
$seriesThreeBannerDesktopBackground: '/series-3-banner-desktop.jpg';
$seriesThreeBannerMobileBackground: '/series-3-banner-mobile.jpg';
$toreyBanner: '/series-3-banner-desktop.jpg';
$toreyBannerMobile: '/series-3-banner-mobile.jpg';
$jamieFoyBanner: '/jamie-foy-banner-desktop.jpg';
$jamieFoyBannerMobile: '/jamie-foy-banner-mobile.jpg';
$blackBox: '/sealed-black.jpg';
// $seriesThreeBannerMobileBackground: '/_next/image?url=%2Fseries-3-banner-mobile.jpg&w=1920&q=75';

$nav-height: 66px;
$footer-height: 80px;
$footer-height-mobile: 142px;
$content-height: calc(100vh - $nav-height - $footer-height);
$content-height-mobile: calc(100vh - $nav-height - $footer-height-mobile);

// ==============================================================================
// color variables
// ==============================================================================
$black: #000;
$white: #fff;
$gray: #c1c1c1;
$gray-500: #777777;
$green: #12eb80;
$black-900: #001121;
$gray-900: #323232;
$gradient-purple-start: #b321de;
$gradient-purple-mid: #9260ff;
$gradient-purple-end: #4544ff;
$gradient-rainbow-start: #5ef7e2;
$gradient-rainbow-mid: #867cfa;
$gradient-rainbow-end: #ee55b2;
$gradient-rainbow-dark-start: #27aaff;
$gradient-rainbow-dark-end: #ca02ff;
$error: rgb(191, 3, 3);

// ==============================================================================
// gradients
// ==============================================================================
$gradient-purple: linear-gradient(
  90deg,
  $gradient-purple-start 0%,
  $gradient-purple-mid 0.01%,
  $gradient-purple-end 101.49%
);

$gradient-rainbow: linear-gradient(
  90deg,
  $gradient-rainbow-start 20%,
  $gradient-rainbow-mid 51%,
  $gradient-rainbow-end
);

$gradient-rainbow-dark: linear-gradient(
  90deg,
  $gradient-rainbow-dark-start 0.49%,
  $gradient-rainbow-dark-end 99.98%
);

// ==============================================================================
// responsive breakpoints
// ==============================================================================
$sm: 700px;
$md: 830px;
$lg: 1440px;
$xl: 1920px;

// ==============================================================================
// z-index
// ==============================================================================
// UX: Change naming for z-index vars
$z-modal: 10000;
$bringToFront: 1000;
$bringForward: 700;
$sendBackward: 400;
$sendToBack: 0;

// TODO: Find better place to organize this var
$input-height: 50px;

// ==============================================================================
// rarity glow colors
// ==============================================================================
$commonGlowColor: #ebfeff;
$commonGlowShador: 0 0 7px #bef6f9, 0 0 10px #4baaaf, 0 0 21px #000000;

$rareGlowColor: #ffcf50;
$rareGlowShadow: 0 0 7px #ffe500, 0 0 10px #615b1f, 0 0 21px #cdc148;

$vaultedRareGlowColor: #d5a7ee;
$vaultedRareGlowShadow: 0 0 7px #9c2e86, 0 0 10px #cc70ba, 0 0 21px #ffd4f6;

$legendaryGlowColor: #fffca7;
$legendaryGlowShadow: 0 0 7px #fbf9a9, 0 0 10px #cfcb49, 0 0 21px #969340;

$border-radius: 5px;
