@import '~/styles/styles/';

.menu {
  display: none;
  position: absolute;
  top: 35px;
  transform: translateY(-50%);
  left: 20px;
  width: 30px;
  height: 20px;

  @include tablet {
    display: block;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
  }

  span,
  &::before,
  &::after {
    transition: all 800ms cubic-bezier(0.075, 0.82, 0.165, 1);
    border-left: 6px solid $gray;
    border-right: 21px solid $white;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  span {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    display: block;
    width: 100%;
    height: 2px;
    border-right: 10px solid $gray;
    border-left: 16px solid $white;
  }

  &.is-active {
    span {
      transform: rotate(-225deg);
      box-shadow: 0 0 7px 2px $gradient-rainbow-dark-start;
      transition: all 800ms cubic-bezier(0.075, 0.82, 0.165, 1),
        box-shadow 800ms 420ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &::before {
      top: -10px;
      opacity: 0;
    }

    &::after {
      top: 50%;
      transform: rotate(-495deg);
      box-shadow: 0 0 7px 2px $gradient-rainbow-end;
      transition: all 800ms cubic-bezier(0.075, 0.82, 0.165, 1),
        box-shadow 800ms 420ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}

.navbar {
  width: 100%;
  background-color: $black-900;
  position: fixed;
  top: 0;
  z-index: $bringToFront * 10;
  height: $nav-height;

  @include tablet {
    transition: all 500ms cubic-bezier(0.56, 0.22, 0.23, 0.91);

    &.is-active {
      .nav-container {
        a {
          transform: translateY(0);
        }
        a,
        button {
          opacity: 1;
        }
        button {
          transition-delay: calc(100ms * 7);
        }
      }
      height: 100vh;
      @include stagger-animation('a', 90ms, 5);
    }
  }
}

.nav-container {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  margin: 0 auto;
  height: 100%;

  @include tablet {
    transition: all 800ms cubic-bezier(0.075, 0.82, 0.165, 1);
    grid-auto-flow: row;
    justify-content: center;
    align-content: start;
    grid-row-gap: 35px;
    padding: 8px 0 50px;
    overflow: hidden;

    a:not(.abd-logo) {
      transform: translateY(-14px);
    }

    a:not(.abd-logo),
    button {
      opacity: 0;
    }
  }

  > a {
    height: 50px;
    align-self: center;
    justify-self: start;

    @include tablet {
      justify-self: center;
    }
  }

  button {
    justify-self: end;
    align-self: center;
  }
}

.link-container {
  display: grid;
  grid-auto-flow: column;
  place-content: center;
  grid-column-gap: 30px;
  overflow: hidden;

  @include tablet {
    grid-auto-flow: row;
    grid-row-gap: 35px;
  }

  a {
    position: relative;
    justify-self: center;
    text-transform: uppercase;
    color: $gray;
    font-size: 12px;
    font-weight: 900;
    transition: all 500ms cubic-bezier(0.55, 0.27, 0.4, 0.89);

    &::after {
      content: '';
      position: absolute;
      display: block;
      opacity: 0;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 1px;
      background: $gradient-rainbow;
    }

    &:hover,
    &.is-active {
      color: $green;

      &::after {
        opacity: 1;
        transition-duration: 0.8s;
      }
    }
  }
}
