.mailchimp {
  width: 100%;

  button {
    margin-top: 0;
    width: 100%;
  }

  input {
    width: 100%;
    padding: 10px;
    margin: 20px auto;
    background-color: white;
    border-radius: 5px;
    display: block;
    color: black;
  }

  input:focus {
    outline: 2px solid #9260ff;
  }

  button:hover {
    background-position: right center;
    background-size: 185% 100%;
  }
}
